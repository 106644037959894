@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap);
* {
  box-sizing: border-box;
}

html {
  line-height: 1.5;
  font-family: Korolev, Roboto, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 400;
}

body {
  color: white;
  background-color: white;
  font-family: "Korolev";
}

button {
  font-family: "Korolev";
}

a {
  color: #00095b;
  font-family: "Korolev";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  line-height: 1.25;
  font-family: "Korolev";
}

@font-face {
  font-family: "Korolev";
  src: local("Korolev"),
    url(../../static/media/Korolev-Medium.8c492d63.eot) format("eot"),
    url(../../static/media/Korolev-Medium.e2482310.ttf) format("ttf"),
    url(../../static/media/Korolev-Medium.b6b1caaf.woff) format("woff");
  font-weight: 300;
}

@font-face {
  font-family: MaxEmoji;
  src: url(../../static/media/Brand-Live-Emoji.757eaf58.otf) format("opentype");
}

.uppercase {
  text-transform: uppercase;
}

